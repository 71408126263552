<template>
  <div class="list-view product-checkout mt-0">
    <!-- Products List -->
    <div v-show="!isConfirm">
      <e-commerce-checkout-step-cart-products :data="dataPass" />

        <!-- Checkout Options -->
        <div class="checkout-options">
          <b-card>
            <div class="price-details">
              <h6 class="price-title mb-2" style="font-size: 1.25rem">
                ยอดเปิดบิลทั้งหมด
              </h6>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title" style="font-size:1.25rem">
                    สินค้าจำนวน
                  </div>
                  <div class="detail-amt" style="font-size:1.25rem">
                    {{ amountProduct.toLocaleString("en-US") }} ชิ้น
                  </div>
                </li>
                <!-- <li class="price-detail">
                  <div class="detail-title">
                    Delivary Charges
                  </div>
                  <div
                    class="detail-amt discount-amt text-success"
                    style="color: red;"
                  >
                    Free
                  </div>
                </li> -->
              </ul>
              <hr />
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title detail-total" style="font-size:1.25rem">
                    ราคารวมทั้งหมด
                  </div>
                  <div class="detail-amt font-weight-bolder" style="color: red;font-size:1.25rem">
                    {{ amount.toLocaleString("en-US") }} บาท
                  </div>
                </li>
              </ul>
              <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-center
            variant="outline-primary"
          >
            Vertically Center
          </b-button> -->
              <b-button variant="primary" block @click="handlerOpenbill" style="font-size:1.25rem">
                เปิดบิลสินค้า
              </b-button>
            </div>
          </b-card>
        </div>
    </div>
    <div v-show="isConfirm">
        <!-- Checkout Options -->
        <div class="checkout-options">
          <b-card class="px-1 pt-0">
            <h6 class="invoice-title mt-2 mb-2" style="font-size: 1.25rem">
                  รายการสินค้า                
                </h6>
                <!-- product -->
                <div v-for="item in selectedProducts" :keys="item.productId">
                  <h4>
                    {{ item.productName }}        
                  </h4>
                  <ul class="list-unstyled">
                    <li
                      class="price-detail  py-50"
                      style="display: flex; justify-content: space-between;"
                    >
                      <div class="detail-title detail-total">
                        ราคาต่อชิ้น
                      </div>
                      <div
                        class="detail-amt font-weight-bolder"
                        
                      >
                      {{ item.price.toLocaleString("en-US") }} บาท   
                      </div>
                    </li>
                    <li
                      class="price-detail py-50"
                      style="display: flex; justify-content: space-between;"
                    >
                      <div class="detail-title detail-total">
                        จำนวน
                      </div>
                      <div
                        class="detail-amt font-weight-bolder"
                       
                      >
                      {{ item.qty.toLocaleString("en-US") }} ชิ้น
                      </div>
                    </li>
                    <hr class="pt-0" />
                    <li
                      class="price-detail"
                      style="display: flex; justify-content: space-between;"
                    >
                      <div class="detail-title detail-total">
                        ราคารวม
                      </div>
                      <div
                        class="detail-amt font-weight-bolder"
                        style="color: #0085FF;"
                      >
                      {{ (Number(item.price) * Number(item.qty)).toLocaleString("en-US")}} บาท   
                      </div>
                    </li>
                  </ul>
                </div>
                <hr />

                <h6 class="invoice-title mt-2 mb-2" style="font-size: 1.25rem">
                  ยอดเปิดบิลทั้งหมด              
                </h6>
                <div>                
                  <ul class="list-unstyled">
                    <li
                      class="price-detail  py-50"
                      style="display: flex; justify-content: space-between;"
                    >
                      <div class="detail-title detail-total">
                        ราคาปกติ
                      </div>
                      <div
                        class="detail-amt font-weight-bolder"
                    
                      >
                      {{ amount.toLocaleString("en-US") }} บาท
                      </div>
                    </li>
                    <li
                      class="price-detail py-50"
                      style="display: flex; justify-content: space-between;"
                    >
                      <div class="detail-title detail-total">
                        ส่วนต่าง
                      </div>
                      <div
                        class="detail-amt font-weight-bolder"
                        style="color: red;"
                      >
                        0 บาท
                      </div>
                    </li>
                    <hr class="pt-0" />
                    <li
                      class="price-detail"
                      style="display: flex; justify-content: space-between;"
                    >
                      <div class="detail-title detail-total">
                        ราคารวมทั้งหมด
                      </div>
                      <div
                        class="detail-amt font-weight-bolder"
                        style="color: red;font-size: 1.25rem;"
                      >
                      {{ amount.toLocaleString("en-US") }} บาท
                      </div>
                    </li>
                  </ul>
               
              <b-button variant="primary" block @click="handlerConfirmOpenbill" style="font-size:1.25rem">
                ยืนยันการเปิดบิล
              </b-button>
            </div>
          </b-card>
          
          <b-modal
            id="modal-center"
            ref="openbill-modal"
            centered
            title=""
            hide-footer
            ok-only
            ok-title="Accept"
          >
          <div style="height: 30rem; display: flex;align-items: center;justify-content: center;">
            <b-row>
              <b-col cols="12" style="display: flex;justify-content: center;" class="mb-2">
                <feather-icon icon="CheckCircleIcon" size="50" style="color: green"/>
              
              </b-col>
              <b-col cols="12" style="display: flex;justify-content: center;" class="mb-2">
              
                <h2>เปิดบิลเรียบร้อยเเล้ว</h2>
              </b-col>
              <b-col cols="12" style="display: flex;justify-content: center;">
                <b-button variant="primary"  @click="handlerToHis" style="font-size:1.25rem">
                  OK
                </b-button>
              </b-col>
            </b-row>
           
          </div>
          </b-modal>
        </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BModal,
  VBModal,
  BRow,BCol
} from "bootstrap-vue";
import ECommerceCheckoutStepCartProducts from "./ECommerceCheckoutStepCartProducts.vue";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import openbillStore from "./openbillStoreModule";
import store from "@/store";
import { getUserData } from "@/auth/utils";
import Ripple from 'vue-ripple-directive'
export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    ECommerceCheckoutStepCartProducts,
    ToastificationContent,
    BModal,
    BRow,BCol
  },
  data() {
    return {
      isConfirm: false
    };
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    // Register module
    if (!store.hasModule("openbillStore"))
      store.registerModule("openbillStore", openbillStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("openbillStore"))
        store.unregisterModule("openbillStore");
    });

    const selectedProducts = ref([]);

    const products = ref([])
    
    const fetchProducts = () => {
      store.dispatch("openbillStore/fetchProducts").then((response) => {
        // products.value = response.data.data;
        response.data.data.forEach((item) => {
          products.value.push({
            ...item,
            qty: 1
          })
        })
      });
    
    };
    fetchProducts();

    
    const dataPass = ref({
      selectedProducts,
      products,
    });

    const amount = ref(0)
    const amountProduct = ref(0)
    
    return {
      selectedProducts,
      dataPass,
      amount,
      amountProduct
    };
  },
  watch: {
    selectedProducts: {
      handler(newValue, oldValue) {
        this.amount = 0
        this.amountProduct = 0
        this.selectedProducts.forEach((item) => {
          this.amount += item.price * item.qty
          this.amountProduct += Number(item.qty)
        })
       
      },
      deep: true
    }
  },
  methods: {
    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },
    handlerOpenbill() {
      const userData = getUserData()
      if (this.selectedProducts.length > 0) {

        let saveModel = []
        this.selectedProducts.forEach((item) => {
          saveModel.push({
            cartId: "00000000-0000-0000-0000-000000000000",
            productId: item.productId,
            contactId: "00000000-0000-0000-0000-000000000000",
            userId: userData.userId,
            qty: item.qty,
            price: item.price,
            minPrice: item.minPrice,
            maxPrice: item.maxPrice
          })
        })
        store
          .dispatch("openbillStore/handlerOpenbill", saveModel)
          .then((res) => {
            this.isConfirm = true
            this.showToast("success", "CheckIcon", "Saved Successfully.");
          })
          .catch((error) => {
            this.showToast("danger", "XCircleIcon", error);
          });
      }

      // this.$router.push({ name: "dashboard-ecommerce" }).then(() => {
      //   this.showToast("success", "CheckIcon", "Saved Successfully.");
      // });
    },
    handlerToHis() {
      this.$refs['openbill-modal'].hide()
      this.$router.push({ name: "history-order" })
    },
    handlerConfirmOpenbill() {
      const userData = getUserData()
      var productList = []
      this.selectedProducts.forEach((item) => {
        productList.push({
          productId: item.productId,
          qty: item.qty
        })
      })
      const saveModel = {   
        orderType: "openbill",
        userId: userData.userId,
        orderLists: productList,
        totalPrice: this.amount,
        totalPriceStd: this.amount,
        orderStatus: 1,
        orderPayment: 0
      }
      store
        .dispatch("openbillStore/handlerConfirmOpenbill", saveModel)
        .then((res) => {
          if ( res.status == 200) {
            this.$refs['openbill-modal'].show()

            this.isConfirm = false
          }
        })
        .catch((error) => {
          this.showToast("danger", "XCircleIcon", error);
        });

     
     
    },
  },
};
</script>
